import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

import "./App.scss";
import Routes from "./utils/routes";
import { AuthProvider } from "./context/auth-context";
import { FetchProvider } from "./context/fetch-context";

const theme = createTheme({
  palette: {
    primary: {
      main: "#77B3E1",
    }, //color: "#1B1477", // ProfitFlow Dark Blue || lightGreen (/@material-ui/core/colors) || "#77B3E1" // HomEvap Light Blue
    secondary: {
      main: "#CAD754",
    }, //color: "#FB7668", // ProfitFlow Light Orange || lightBlue (/@material-ui/core/colors) || "#CAD754" // HomEvap Light Green
  },
  typography: {
    fontFamily: "Quicksand",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

function App() {
  return (
    <Router>
      <AuthProvider>
        <FetchProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </FetchProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
