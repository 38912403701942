import React from "react";

import { Button, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const FourOFour = () => {
  return (
    <Container>
      <Typography variant="h2">404</Typography>
      <Typography variant="h3">Pagina niet gevonden.</Typography>
      <Button
        component={Link}
        to="/login"
        variant="contained"
        color="primary"
        style={{ marginTop: 15 }}
      >
        Terug naar login
      </Button>
    </Container>
  );
};

export default FourOFour;
