import React, { useContext, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";

import { AuthContext } from "../../context/auth-context";
import { PageData } from "../../utils/page-data";
import drawerImage from "../../assets/air.jpeg";

import Copyright from "../copyright/copyright.component";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(3),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundImage: `url(${drawerImage})`,
    },
    root: {
      display: "flex",
    },
    active: {
      background: theme.palette.primary.dark,
    },
    title: {
      padding: theme.spacing(2),
      color: "#ffffff",
    },
    appbar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      objectFit: "initial",
      backgroundImage: `url(${drawerImage})`,
      background: "#12406A",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundImage: `url(${drawerImage})`,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    toolbar: theme.mixins.toolbar,
    filler: {
      flexGrow: 1,
    },
    button: {
      margin: theme.spacing(1),
      color: "#ffffff",
    },
    icon: {
      fill: theme.palette.primary.light,
    },
    closeIcon: {
      color: "#ffffff",
    },
    text: {
      color: "#ffffff",
    },
    profileIcon: {
      color: "#fff",
    },
    test: {
      backgroundImage: `url(${drawerImage})`,
    },
  };
});

const Layout = ({ children }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const logoutHandler = () => {
    auth.logout();
  };

  const AuthenticatedRoutes = () => {
    if (auth.isAuthenticated() && !auth.isAdmin()) {
      return PageData.filter((page) => page.admin !== true);
    } else {
      return PageData;
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* App bar */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={3}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Button className={classes.button} component={Link} to={"/"}>
            Dashboard
          </Button>
          <Button className={classes.button} component={Link} to={"/webshop"}>
            Bestellen
          </Button>
          <div className={classes.filler}></div>
          <IconButton onClick={() => history.push("/profile")}>
            <PersonIcon className={classes.profileIcon} />
          </IconButton>
          <Button
            className={classes.button}
            component={Link}
            to={"/login"}
            onClick={logoutHandler}
          >
            Uitloggen
          </Button>
        </Toolbar>
      </AppBar>

      {/* Side drawer */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h5" className={classes.title}>
            HomEvap Dealerportal
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon className={classes.closeIcon} />
          </IconButton>
        </div>

        {/* List/Links */}
        <List>
          {AuthenticatedRoutes().map((item) => (
            <ListItem
              key={item.text}
              button
              onClick={() => history.push(item.path)}
              className={
                location.pathname === item.path ? classes.active : null
              }
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                className={classes.text}
                primary={item.text}
              ></ListItemText>
            </ListItem>
          ))}
        </List>
        <Copyright />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {/* App.js children */}
        <div className={classes.page}>
          <div className={classes.toolbar}></div>
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
