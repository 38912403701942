import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import HomEvapLogo from "../../assets/homevap_logo.png";

const useStyles = makeStyles((theme) => {
  return {
    copyright: {
      position: "fixed",
      bottom: 5,
      alignSelf: "center",
      color: theme.palette.primary.main,
    },
    logo: {
      position: "fixed",
      alignSelf: "center",
      width: 180,
      bottom: 25,
    },
  };
});

const Copyright = () => {
  const classes = useStyles();
  return (
    <>
      <img src={HomEvapLogo} alt="Homevap-logo" className={classes.logo} />
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className={classes.copyright}
      >
        {"Copyright © "}
        <Link
          color="ffffff"
          to={{ pathname: "https://www.homevap.com" }}
          target="_blank"
        >
          HomEvap
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </>
  );
};

export default Copyright;
