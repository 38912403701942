import React, { useContext, lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Container, Typography } from "@material-ui/core";

import { AuthContext } from "../context/auth-context";
import Layout from "../components/layout/layout.component";
import ErrorBoundary from "../components/error-boundary/error-boundary.component";

import FourOFour from "../pages/four-o-four/four-o-four.component";
import Login from "../pages/login/login.component";

const HomePage = lazy(() => import("../pages/homepage/homepage.component"));
const Profile = lazy(() => import("../pages/profile/profile.component"));
const Invoice = lazy(() => import("../pages/invoice/invoice.component"));
const Dealer = lazy(() => import("../pages/dealer/dealer.component"));
const Stock = lazy(() => import("../pages/stock/stock.component"));
const Webshop = lazy(() => import("../pages/webshop/webshop.component"));

const LoadingFallback = () => (
  <Container>
    <Typography>Loading...</Typography>
  </Container>
);

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="*">
      <FourOFour />
    </Route>
    <Route path="/">
      <Login />
    </Route>
  </Switch>
);

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() ? (
          <Layout>{children}</Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    ></Route>
  );
};

const AdminRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() && auth.isAdmin() ? (
          <Layout>{children}</Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    ></Route>
  );
};

export const Routes = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <AuthenticatedRoute exact path="/">
            <HomePage />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/profile">
            <Profile />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/invoices">
            <Invoice />
          </AuthenticatedRoute>
          <AdminRoute path="/dealers">
            <Dealer />
          </AdminRoute>
          <AuthenticatedRoute path="/stock">
            <Stock />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/webshop">
            <Webshop />
          </AuthenticatedRoute>
          <UnauthenticatedRoutes />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
