import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import dashboardImage from "../assets/logo/dashboard.svg";
import webshopImage from "../assets/logo/webshop.svg";
import invoiceImage from "../assets/logo/invoice.svg";
import stockImage from "../assets/logo/stock.svg";
import dealerImage from "../assets/logo/dealer.svg";
import profileImage from "../assets/logo/profile_temp.svg";

// Find more alike images from https://www.freepik.com/jcomp

export const PageData = [
  {
    text: "Dashboard.",
    icon: <DashboardIcon fontSize="large" color="secondary" />,
    image: dashboardImage,
    path: "/",
    admin: false,
  },
  {
    text: "Bestellen.",
    icon: <ShoppingCartIcon fontSize="large" color="secondary" />,
    image: webshopImage,
    path: "/webshop",
    admin: false,
  },
  {
    text: "Profiel.",
    icon: <PersonIcon fontSize="large" color="secondary" />,
    image: profileImage,
    path: "/profile",
    admin: false,
  },
  {
    text: "Dealers.",
    icon: <PeopleIcon fontSize="large" color="secondary" />,
    image: dealerImage,
    path: "/dealers",
    admin: true,
  },
  {
    text: "Facturen.",
    icon: <DescriptionIcon fontSize="large" color="secondary" />,
    image: invoiceImage,
    path: "/invoices",
    admin: false,
  },
  {
    text: "Voorraad.",
    icon: <LocalShippingIcon fontSize="large" color="secondary" />,
    image: stockImage,
    path: "/stock",
    admin: false,
  },
];
